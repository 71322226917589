import request from '@/utils/request'


// 查询国家列表
export function listCountry(query) {
  return request({
    url: '/country/country/list',
    method: 'get',
    params: query
  })
}

// 查询国家分页
export function pageCountry(query) {
  return request({
    url: '/country/country/page',
    method: 'get',
    params: query
  })
}

// 查询国家详细
export function getCountry(data) {
  return request({
    url: '/country/country/detail',
    method: 'get',
    params: data
  })
}

// 新增国家
export function addCountry(data) {
  return request({
    url: '/country/country/add',
    method: 'post',
    data: data
  })
}

// 修改国家
export function updateCountry(data) {
  return request({
    url: '/country/country/edit',
    method: 'post',
    data: data
  })
}

// 删除国家
export function delCountry(data) {
  return request({
    url: '/country/country/delete',
    method: 'post',
    data: data
  })
}
